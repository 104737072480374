<div class="container border">
  <div class="flex flex-column">
    <div class="row">
      <b>{{ egg?.name || 'Egg' }}</b> @if (egg) { ({{ egg.code }}) }
    </div>
    @if (egg) {
      <!-- Required items -->
      <div class="row">
        <div class="flex">
          <span>Required items:</span>
          @if (!egg.items) {
            <span>Unknown</span>
          } @else if (!egg.items.length) {
            <span>None</span>
          } @else {
            @for (item of egg.items; track item) {
              <span class="spoiler" onclick="this.classList.toggle('spoiled')">{{ item }}</span>
            }
          }
        </div>
      </div>
      <!-- Tracking -->
      <div class="row">
        <div class="flex">
          <button class="btn btn-primary" (click)="toggleFound()">
            <span class="mono">({{ egg.obtained ? 'X' : 'O'}})</span>
            Found
          </button>
          <button class="btn btn-primary" (click)="toggleVisible()">
            <span class="mono">({{ egg.visible ? 'X' : 'O'}})</span>
            Show on map
          </button>
        </div>
      </div>
      <!-- Reveal -->
      <div class="row">
        <div class="flex">
          <button class="btn btn-primary" (click)="gotoQuadrant()">
            Go to map quadrant
          </button>
          <button class="btn btn-primary" (click)="gotoTile()">
            Go to map tile
          </button>
        </div>
      </div>
    } @else {
      <div>Please select an egg first.</div>
    }
  </div>
</div>
