<div class="map" #mapDiv></div>
<div class="map-sidebar-unfold border" (click)="isSidebarFolded=false">&gt;</div>
<div class="map-sidebar pad pt-0" [hidden]="isSidebarFolded">
  <div class="sidebar-fold border" (click)="isSidebarFolded=true">&lt;</div>
  <!-- Map layer -->
  <div class="sidebar-section">
    <h2 class="sidebar-marker-group">Map layer</h2>
    <div class="flex flex-column">
      <div class="flex flex-wrap">
        <button type="button" [class.border-active]="mapLayers['map'].visible" (click)="showMapLayer('map')">
          <span class="material-symbols-outlined">map</span>&nbsp;
          <span>Map</span>
        </button>
        <button type="button" [class.border-active]="mapLayers['cheatMap'].visible" (click)="showMapLayer('cheatMap')">
          <span class="material-symbols-outlined">joystick</span>&nbsp;
          <span>Cheat areas</span>
        </button>
      <div class="flex flex-wrap">
        <button type="button" [class.border-active]="mapLayers['border'].visible" (click)="showMapLayer('border')">
          <span class="material-symbols-outlined">border_style</span>&nbsp;
          <span>Borders</span>
        </button>
        <button type="button" [class.border-active]="mapLayers['coords'].visible" (click)="showMapLayer('coords')">
          <span class="material-symbols-outlined">numbers</span>&nbsp;
          <span>Coordinates</span>
        </button>
      </div>
    </div>
  </div>
  <!-- World layer -->
  <div class="sidebar-section">
    <h2 class="sidebar-marker-group">World layer</h2>
    <div class="flex flex-wrap">
      <button type="button" class="button-layer" [class.border-active]="mapLayers['world'].visible" (click)="showMapLayer('world')">
        <span class="material-symbols-outlined">ev_shadow</span>&nbsp;
        <span>Minimal</span>
      </button>
      <button type="button" class="button-layer" [class.border-active]="mapLayers['explored'].visible" (click)="showMapLayer('explored')">
        <span class="material-symbols-outlined">public</span>&nbsp;
        <span>Full</span>
      </button>
      <button type="button" class="button-layer" [class.border-active]="mapLayers['bright'].visible" (click)="showMapLayer('bright')">
        <span class="material-symbols-outlined">brightness_high</span>&nbsp;
        <span>Full (bright)</span>
      </button>
      <button type="button" class="button-layer" [class.border-active]="mapLayers['space'].visible" (click)="showMapLayer('space')">
        <span class="material-symbols-outlined">rocket_launch</span>&nbsp;
        <span>Space (spoilers!)</span>
        <input class="input-layer-opacity" type="range" min="0" max="100" step="1" value="100" (input)="setMapLayerOpacity($event, 'space')" (click)="preventDefault($event)">
      </button>
    </div>
  </div>

  <!-- Sidebar markers per section -->
  @for (section of markerSections; track $index) {
    <div class="sidebar-section">
      <h2 class="sidebar-marker-group">
        <span (click)="toggleMarkerSection(section)">
          {{ section.name }}
        </span>
      </h2>
      <div class="flex flex-wrap">
        @for (group of section.groups; track group.name) {
          <button type="button" [class.border-active]="markerLayersVisible[group.name]" (click)="toggleMarkerLayers(group.name)">
            @if (group.labelIcon) { <span class="material-symbols-outlined">{{group.labelIcon}}</span>&nbsp; }
            <span>{{ group.label }}</span>&nbsp;<span>({{ group.markerCount ?? markerLayersCount[group.name] }})</span>
          </button>
        }
      </div>
    </div>
  }

  <!-- Custom markers -->
  @if (map) {
    <div class="sidebar-section">
      <h2>Custom</h2>
      <div class="flex flex-wrap">
      <button type="button" [class.border-active]="editing" (click)="toggleEdit()">
        <span class="material-symbols-outlined">add</span>
        Add group
      </button>
      <button type="button" (click)="importCustomMarkerGroup()">
        <span class="material-symbols-outlined">note_Add</span>
        Import
      </button>
        @for (group of customMarkers; track group.name) {
          <button type="button" [class.border-active]="group.visible" (click)="toggleCustomMarkerGroup(group.name)">
            <span>{{ group.name }} ({{ group.count }})</span>&nbsp;
            <span class="material-symbols-outlined icon-hover" (click)="exportCustomMarkerGroup(group.name); preventDefault($event)">download</span>
            <span class="material-symbols-outlined icon-hover" (click)="toggleEdit(group.name); preventDefault($event)">edit</span>
            <span class="material-symbols-outlined icon-hover" (click)="deleteCustomMarkerGroup(group.name); preventDefault($event)">delete</span>
          </button>
        }
      </div>
    </div>
  }

  <!-- Info -->
  <div class="sidebar-section mt">
    <i>
      Missing markers?<br/>Contact Silverfeelin on Discord!<br/><a href="https://github.com/Silverfeelin/AnimalWell-Helper">GitHub repo</a>
    </i>
  </div>
</div>

@if (map) {
  <app-map-node-editor [map]="map"></app-map-node-editor>
}

@if (editing) {
  <app-map-marker-editor [map]="map" [groupName]="editingGroup" (markerGroupCreated)="onCustomMarkerGroupCreated($event)" (markerGroupUpdated)="onCustomMarkerGroupUpdated($event)"></app-map-marker-editor>
}
